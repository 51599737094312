// ----------------------------------------
// Typeform Styles
// ----------------------------------------

// sidetab
.typeform-sidetab {
  .typeform-sidetab-button {
    width: auto;
    height: 35px;
    border-radius: 0;

    .typeform-sidetab-button-text {
      font-size: 16px;
    }

    .typeform-sidetab-button-icon {
      margin-right: 5px;

      svg {
        width: 22px;
        height: 22px;
      }
    }
  }
}

// bottom popover
.tf-v1-popover {
  bottom: 122px !important;

  .tf-v1-popover-button {
    bottom: 52px;
  }

  .tf-v1-popover-tooltip {
    bottom: 56px;
  }
}
