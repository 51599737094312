// ----------------------------------------
// Footer Styles
// ----------------------------------------

.footer {
  @extend .py-3;
  border-top: 1px solid $soft-black;
  background-color: $white;

  .row {
    @extend .align-items-center;
  }

  .logo {
    @extend .me-auto;

    img {
      width: 150px;
      height: 44px;
    }

    @include for-sm-down {
      width: 100%;
    }
  }

  a {
    &:not(.logo) svg {
      fill: $link-color;
      width: 12px;
      height: 12px;
      @extend .me-1;
      @extend .ms-5;
    }

    &:hover,
    &:focus {
      svg {
        fill: $link-hover-color;
      }
    }

    @include for-sm-down {
      width: 100%;
      display: block;
    }
  }
}
