// ----------------------------------------
// @font-face Declarations
// ----------------------------------------

* {
  font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
  font-size: 14px;
  font-weight: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@mixin arial-reg-34px {
  font-size: 34px;
  line-height: 40px;
}

@mixin arial-reg-30px {
  font-size: 30px;
  line-height: 36px;
}

@mixin arial-reg-22px {
  font-size: 22px;
  line-height: 28px;
}

@mixin arial-reg-20px {
  font-size: 20px;
  line-height: 26px;
}

@mixin arial-reg-19px {
  font-size: 19px;
  line-height: 24px;
}

@mixin arial-reg-16px {
  font-size: 16px;
  line-height: 20px;
}

@mixin arial-reg-12px {
  font-size: 12px;
}

@mixin arial-reg-10px {
  font-size: 10px;
  line-height: 12px;
}
