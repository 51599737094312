// ----------------------------------------
// Moderna Mindsets Assessment Styles
//       --- ASSESSMENT WRAP UP --
// ----------------------------------------

// ASSESSMENT WRAP UP / OUTRO SCREEN
.assessment-wrap {
  h2 {
    font-size: 30px;
    line-height: 30px;
    color: $soft-black;
  }
}

.assessment-wrap--header {
  background-color: $white;
  padding: 30px 0px 10px 0px;
  box-shadow: 0 0.1rem 0.5rem rgba(0, 0, 0, 0.15);
  position: fixed;
  top: 48px;
  left: 0;
  right: 0;
  z-index: 1;

  @include for-md-down {
    position: relative;
    top: 5px;
  }
}
.assessment-wrap--header > div {
  @extend .container;
  position: relative;
  @include for-md-down {
    display: block !important;
  }

  > div {
    display: flex;
    flex-direction: column;

    @include for-md-down {
      display: block;
    }
  }

  img {
    max-width: 400px;
    margin-top: auto;
    margin-bottom: -10px;

    @include for-md-down {
      margin: 0px auto -10px auto;
      display: block;
    }
  }

  .date-completed,
  .report-details {
    font-size: 12px;
    .text-muted {
      color: $dark-gray;
    }
  }

  .assessment-instructions {
    font-size: 22px;
    line-height: 30px;
    color: $soft-black;
    margin: auto 330px 20px 0px;

    @include for-xl {
      margin: auto 150px 20px 0px;
    }
    @include for-lg {
      margin: auto -20px 20px 0px;
    }
    @include for-md-down {
      margin: 10px 0px 20px 0px;
      font-size: 16px;
      line-height: 24px;
    }

    strong {
      font-size: 22px;
      font-weight: bold;
    }
  }
}

.assessment-wrap--rows {
  @extend .container-fluid;
  padding: 40px 0px;
  margin-top: 224px;
  background-color: $deep-blue;

  @include for-md-down {
    margin-top: 0px;
  }
  @include for-xs-only {
    padding: 40px 20px;
  }

  > div {
    @extend .container;
    background-color: $white;
    border-radius: 5px;
    border: 1px solid $mid-gray;
    padding: 20px;
  }

  .table-responsive {
    .table {
      width: 100%;

      thead {
        tr th {
          padding: 10px 0px;
          font-size: 14px;
          font-weight: bold;
          border-bottom: 1px solid $mid-blue;
        }
      }

      tbody {
        tr {
          border-bottom: 1px solid $mid-blue;

          &:last-of-type {
            border-bottom: transparent;
          }
        }
        tr td {
          padding: 14px 0px;
          font-size: 16px;
        }

        .report-row--count {
          font-weight: bold;
          vertical-align: top;
          padding-left: 6px;
          padding-right: 6px;
        }

        .report-row--text {
          font-size: 16px;
          padding-right: 150px;

          @include for-lg {
            padding-right: 40px;
          }
          @include for-md-down {
            padding-right: 0px;
            min-width: 300px;
          }

          strong {
            font-size: 16px;
          }

          .report-row--reflection {
            background-color: $light-blue;
            font-size: 14px;
            margin-top: 10px;
            padding: 10px;

            .reflection-text {
              color: $access-blue;
            }
          }
        }

        .report-row--learn {
          width: 150px;

          @include for-lg-down {
            width: 130px;
          }

          @include for-md-down {
            min-width: 110px;
            padding: 20px 8px;
            font-size: 14px;
          }
          @include for-xs-only {
            min-width: 110px;
            font-size: 13px;
          }

          svg {
            width: 16px;
            height: 16px;
            fill: $access-blue;
          }

          .learn-more-link {
            cursor: pointer;

            @include for-xs-only {
              font-size: 13px;
            }

            &:hover {
              color: $access-blue;
            }

            svg {
              width: 16px;
              height: 16px;
              fill: $access-blue;
            }
          }
        }

        .report-row--result {
          width: 175px;
          background-color: $light-blue;
          font-weight: bold;
          padding: 16px;

          @include for-md-down {
            padding: 20px 10px;
            font-size: 14px;
            min-width: 130px;
          }
          @include for-xs-only {
            font-size: 13px;
          }
        }

        .report-row--label {
          width: 150px;
          padding: 16px;

          @include for-md-down {
            padding: 20px 10px;
            font-size: 14px;
          }
          @include for-xs-only {
            font-size: 13px;
          }
        }

        .report-row--leadership {
          background-color: $assessments-pale-yellow;
          width: 175px;
          font-weight: bold;
          padding: 16px;

          @include for-md-down {
            padding: 20px 10px;
            font-size: 14px;
            min-width: 130px;
          }
          @include for-xs-only {
            font-size: 13px;
          }
        }
      }
    }
  }
}

.assessment-wrap--footer {
  background-color: $deep-blue;
  color: $white;
}

.assessment-wrap--footer > div {
  @extend .container;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 120px;

  @include for-xs-only {
    display: block;
    padding: 1px 20px 20px 20px;
  }

  .assessment-instructions {
    font-size: 22px;
    line-height: 30px;
    color: $white;
    margin: 20px 0px;
    padding-right: 10px;

    @include for-xs-only {
      padding-right: 0px;
    }

    strong {
      font-size: 22px;
      font-weight: bold;
    }
  }
  .assessment-btn-big {
    min-width: 215px;
  }
}

// COACH REPORT
.coach-report {
  .assessment-wrap--header > div {
    .assessment-instructions {
      margin: auto 180px 20px 0px;

      @include for-xl {
        margin: auto 0px 20px 0px;
      }
      @include for-lg {
        margin: auto 0px 20px 0px;
      }
      @include for-md-down {
        margin: 10px 0px 20px 0px;
      }
    }
  }
}

.coach-notepad {
  background-color: $light-blue;
  @extend .container-fluid;
  padding: 40px 0px;
}

.coach-notepad--wrapper {
  @extend .container;
  border: 1px solid $mid-gray;
  border-radius: 5px;
  background-color: $white;
  padding: 20px 30px;

  textarea {
    width: 100%;
    min-height: 150px;
    border: 1px solid $mid-gray;
    border-radius: 5px;
    margin: 10px 0px;
  }
}
