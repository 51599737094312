// ----------------------------------------
// Error Styles
// ----------------------------------------

.error-page {
	.mt-lg {
		margin-top: 7rem;
	}

	.flex {
		display: flex;
		justify-content: space-between;

		.flex-item {
			flex: 0 0 auto;
			width: 46%;

			a {
				color: #0278A3;
				text-decoration: none;
				overflow-wrap: break-word;
				hyphens: manual;
			}

			h1 {
				margin: 0px;
				font-size: 4em;
				text-align: right;
			}

			h2 {
				margin-top: 8px;
			}

			.socket {
				margin-top: -11.5rem;
			}
		}
	}

	@media only screen and (max-width: 567px) {
		.flex {
			flex-wrap: wrap;

			.flex-item {
				width: 100%;

				h1 {
					text-align: left;
					margin-bottom: 1rem;
				}

				.plug {
					width: 300px;
					height: auto;
					margin-top: 6rem;
					transform: rotate(90deg);
				}

				.socket {
					margin-top: 3rem;
					transform: rotate(90deg);
				}
			}
		}
	}
}
