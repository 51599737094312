// ----------------------------------------
// False Footer Styles
// ----------------------------------------

.dash-falsefooter {
	background-color: $deep-blue;
	height: 100%;

	.falsefooter--link {
		@include arial-reg-16px;
		width: 100%;
		display: inline-flex;
		align-items: center;

		a {
			@extend .p-3;
			@include arial-reg-16px;
			width: 100%;
			display: inline-flex;
			align-items: center;
			color: $white;
			border-bottom: 1px solid $moderna-blue;
		}

		svg {
			width: 16px;
			height: 16px;
			margin-right: 1rem;
			fill: $white;
		}

		&:hover,
		&:focus {
			a {
				color: $moderna-blue;
			}

			svg {
				fill: $moderna-blue;
			}
		}
	}
}

.falsefooter-columns {
	column-count: 3;
  column-gap: 2rem;

	@include for-md {
		column-count: 2;
	}

	@include for-sm-down {
		column-count: 1;
	}
}
