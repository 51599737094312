// ----------------------------------------
// Apps Styles
// ----------------------------------------

.app-index-page {
    background-color: $pale-gray;
}

.app-page {
  .content-keyresources {
    @extend .col-12;
  }
}

.app-header {
    background-color: $white;

    // .image-cover img {
    //   width: 100%;
    //   object-fit: cover;
    // 	min-height: 300px;
    // }

    .image-cover {
    	background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      position: relative;
      height: auto;
    	min-height: 200px;
    }
}

.app-library {
    @extend .pb-4;
}

.app-category {
    @extend .mt-4;
    @extend .p-4;
    border-radius: 15px;
    background-color: $white;

    .card {
        border: 2px solid $access-blue;
        border-radius: 5px;
        background-color: $white;

        .card-header {
            padding-bottom: 0px;
            border-bottom: 0px;
            background-color: $white;

            .app-logo {
                min-width: 70%;

                .set-height {
                  display: block;
                  height: 42px;
                }
            }

            .add-btn svg {
                width: 24px;
                height: 22px;
                @extend .my-3;
            }
        }

        .card-body {
            padding-bottom: 0px;
        }

        .card-footer {
            padding-top: 0px;
            border-top: 0px;
            background-color: $white;

            .launch-btn,
            .learnmore-btn {
                @extend .mt-2;
            }
        }
    }
}

.col-description {
  img,
  .richtext-image {
    max-width: 100%;
    height: auto;
    border-radius: 6px;
    border: 2px solid $soft-black;
  }
}
