// ----------------------------------------
// Typography Mixins and Classes
// ----------------------------------------

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  line-height: normal;
  font-weight: normal;
}

h1,
.h1 {
  @include arial-reg-34px;
  margin-top: 1rem;
}

h2,
.h2 {
  @include arial-reg-30px;
}

h3,
.h3 {
  @include arial-reg-20px;
  text-transform: uppercase;
  letter-spacing: 0.25em;
}

h4,
.h4 {
  @include arial-reg-22px;
}

h5,
.h5,
.tile-title {
  @include arial-reg-19px;
}

h6,
.h6,
.text-callout {
  @include arial-reg-16px;
}

.date,
.btn,
.navbar a,
.footer a {
  @include arial-reg-12px;
}

.xl-single-character {
  font-size: 60px;
  line-height: 40px;
}

.text-moderna-orange {
  color: $moderna-orange;
}

.text-assessments-red {
  color: $assessments-red;
}
