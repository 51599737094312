// ----------------------------------------
// Department Styles
// ----------------------------------------

.department-page {
	.dash-whatsnew--secondary {
		.card {
		  border: 2px solid $link-color;
		}
	}

	.dash-whatsnew--secondary,
	.content-keyresources {
		flex: 0 0 auto;
		width: 25%;
		// width: 33.33333333%;

		@include for-lg {
			width: 33.33333333%;
			// width: 50%;
		}

		@include for-md {
			width: 50%;
		}

		@include for-sm-down {
			width: 100%;
		}
	}

	.dash-whatsnew--secondary {
		.card-body {
			h5 {
				@include for-sm-up {
					min-height: 50px;
					margin-bottom: 0px;
				}
			}

			// line-clamp
			.line-clamped {
				display: -webkit-box;
			  -webkit-line-clamp: 5;
			  -webkit-box-orient: vertical;
			  overflow: hidden;
			}
		}
	}
}

.section-header {
	margin: 3rem 0 1rem;
	padding: 1rem;
	border-left: 10px solid $gray-blue;
	background-color: $light-blue;

	&:first-child {
		margin-top: 0px;
		margin-bottom: 2rem;
	}

	h3 {
		margin-bottom: 0px;
	}
}

.section-subheader {
	margin: 3rem 0 1rem;
	padding: 1rem;
	background-color: $pale-gray;

	h4 {
		margin-bottom: 0px;
	}
}

.information-box {
	@extend .container;
	margin: 0;
	padding: 1rem;
	border-radius: 6px;
	border: 1px solid $mid-gray;
	background-color: $white;

	p {
		margin-bottom: 0px;
	}
}

.information-box--link {
	display: block;
	margin: .5rem 0;
	padding: .25rem 0;
	border-radius: 6px;
	border: 1px solid $link-color;

	.information-box--link-title {
		display: flex;
		justify-content: space-between;
		flex-direction: row;
		align-items: center;
		margin: .5rem .5rem 0 .5rem;
		padding: .25rem;

		h5 {
			margin-bottom: 0px;
		}

		svg {
			fill: $link-color;
			width: 10px;
			height: 10px;
		}
	}

	p {
		color: $dark-gray;
		margin: 0 .5rem .5rem;
		padding: 0 .25rem .25rem;
	}

	&:hover,
	&:focus {
		border-color: $link-hover-color;

		// p {color: $soft-black;}

		svg {
			fill: $link-hover-color;
		}
	}
}

.information-box--comingsoon {
	display: block;
	margin: .5rem 0;
	padding: 1rem;
	border-radius: 6px;
	border: 1px solid $mid-gray;

	h5 {
		margin-bottom: .5rem;
	}

	p {
		margin-bottom: 0px;
	}
}
