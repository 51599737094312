// ----------------------------------------
// Community Styles
// ----------------------------------------

.dash-community {
	@extend .mb-5;

	h2 {
		color: $access-blue;
		@extend .mt-3;
		@extend .mb-3;
	}

	.dash-community--card {
		@extend .card;
		@extend .h-100;
		color: $white;
		border-radius: 6px;
		border: 3px solid $mid-gray;

    @include for-xl-up {
      max-height: 225px;
    }

		@include for-lg {
			min-height: 200px;
		}

		@include for-md-down {
			max-height: 175px;
		}

		img, .card-img {
			width: 100%;
			height: 100%;
		  object-fit: cover;
			border-radius: 3px;
		}

		.card-img-overlay {
			background: rgba(0,0,0,.7);
			background: linear-gradient(74deg, rgba(0,0,0,1) 20%, rgba(0,0,0,0.75) 40%, rgba(255,255,255,0) 80%);
		}

		.card-title {
	    display: inline-block;
	    position: absolute;
	    top: 50%;
	    left: 1rem;
	    transform: translateY(-50%);
			width: 50%;

			h2, h3, h4, p, em, i {
				@include arial-reg-19px;
				margin-bottom: 0px;
			}
		}

		em, i {
			font-weight: 400;
			font-style: normal;
		}

		&.mindsets,
		&.blue {
			border-color: $moderna-blue;

			.card-badge {
				background-color: $moderna-blue;
			}

			em, i {
				color: $moderna-blue;
			}
		}

		&.learning,
		&.belonging,
		&.orange {
			border-color: $moderna-orange;

			.card-badge {
				background-color: $moderna-orange;
			}

			em, i {
				color: $moderna-orange;
			}
		}

		&.wellness,
		&.green {
			border-color: $moderna-green;

			.card-badge {
				background-color: $moderna-green;
			}

			em, i {
				color: $moderna-green;
			}
		}

		&:hover,
		&:focus {
			border-color: $soft-black;

			.card-badge {
				color: $white;
				background-color: $soft-black;;
			}
		}
	}
}
