// ----------------------------------------
// Color Variables
// ----------------------------------------

// primary palette
$moderna-blue: #00aeef;
$access-blue: #0278a3;
$soft-black: #2f2d2d;

// secondary palettes
$moderna-red: #e31837;
$moderna-orange: #f8971d;
$moderna-green: #7ac143;

$deep-blue: #003b5d;
$gray-blue: #9bc3d2;
$sky-blue: #87d6f9;
$mid-blue: #b5e8f6;
$another-mid-blue: #bfe6f6;
$almost-mid-blue: #c3ebfc;
$pale-blue: #d6efff;
$light-blue: #e9f9ff;
$lightest-blue: #f7fdff;

$dark-gray: #636463;
$mid-gray: #9f9f9e;
$light-gray: #dddddd;
$pale-gray: #f5f5f5;

// neutral palette
$white: #fff;
$black: #000;

// link palette
$link-color: $access-blue;
$link-hover-color: $soft-black;
$link-hover-fill: $moderna-green;

$assessments-red: #fc632d;
$assessments-pale-yellow: #fffde9;

// custom content palette
$custom-cyan: #2aace2;
$custom-teal: #1ac7b4;
$custom-purple: #824fcf;
$custom-salmon: #ff8d87;

// banner gradient
$banner-blue: #141c6e;
$banner-red: #e21837;
