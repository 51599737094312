// ----------------------------------------
// New Hire Index Styles
// ----------------------------------------

.newhire-index {
	.app-filtering {
		border: 0px;
		background-color: unset;
	}

	.newhire--banner {
		background-position: left center;
	}

	.newhire-index--pagetitle {
		@extend .mt-5;
		@extend .mb-3;
		@extend .align-items-center;
	}

	.newhire--section {
		margin-bottom: 4rem;
		padding: 1.5rem;
		border-radius: 6px;
		border-style: solid;
		border-width: 3px;
		background-color: $white;

		.section-header {
			margin-bottom: .5rem;
		}

		.section-subheader {
  		margin-top: 1rem;

			&.subsubheader {
				margin-bottom: 0px;
				padding-bottom: 0px;
				background-color: unset;

				h4 {
					@include arial-reg-20px;
					font-weight: 700;
				}
			}
		}

		.section-subheader--description > * {
			&:last-child {
				margin-bottom: 0px;
			}
		}
	}

	.newhire--index-callout {
		display: flex;
    flex-direction: column;
    height: 100%;
		// margin-bottom: 3rem;
		padding: 0px 1rem;
		border-radius: 6px;
		border-style: solid;
		border-width: 3px;
		background-color: $white;

		svg {
			margin-left: 1.5rem;
			fill: $link-color;
		}

		.newhire--pagelink-title {
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin-top: -27px;
			padding: .75rem;
			border-radius: 6px;
			border: 3px solid $mid-gray;
			background-color: $white;

			h5 {
				color: $soft-black;
				margin-bottom: 0px;
				flex: 1 1 auto;
			}

			div {
				display: flex;
				flex: 1 1 40%;
				flex-wrap: nowrap;
				justify-content: flex-end;
				align-items: center;
    		text-align: right;
			}

			svg {
				width: 20px;
				height: 24px;
				margin-left: 1rem;
			}

			&:hover,
			&:focus {
				color: $soft-black;

				svg {
					fill: $link-hover-color;
				}
			}
		}

		.newhire--pagelink-link {
			display: flex;
			justify-content: space-between;
			align-items: center;
			// margin: .5rem 0;
			padding: .75rem;
			border-bottom: 1px solid $mid-gray;

			&:last-child {
				border-bottom: 0px;
			}

			svg {
				width: 10px;
				height: 14px;
			}

			&:hover,
			&:focus {
				svg {
					fill: $link-hover-color;
				}
			}
		}
	}
}
