// ----------------------------------------
// Moderna Mindsets Assessment Styles
//       --- ASSESSMENT REFLECT --
// ----------------------------------------

// ASSESSMENT REFLECT - TEXTAREAS FOR THOUGHTS
.assessment-survey.reflection {
  .assessment-survey--rows {
    margin-top: 130px;

    @include for-lg {
      margin-top: 158px;
    }

    @include for-md-down {
      margin-top: 0px;
    }
  }
}
.assessment-reflection {
  @extend .container;
}

.assessment-reflection--lead {
  background-color: $light-blue;
  padding: 10px 20px;
  margin-bottom: 10px;

  h3 {
    font-size: 20px;
    letter-spacing: 4px;
    @include for-xs-only {
      font-size: 16px;
      letter-spacing: 3px;
    }
  }

  p {
    margin-bottom: 0px;
    font-size: 14px;
    line-height: 20px;
  }
}

.assessment-reflection--wrapper {
  display: flex;
  flex-direction: column;

  &:last-child .assessment-reflection--textarea {
    margin-bottom: 50px;
  }
}

.assessment-reflection--statement {
  background-color: $pale-gray;
  font-size: 16px;
  font-weight: bold;
  padding: 10px 20px;
  margin: 8px 20px;
}

.assessment-reflection--textarea {
  margin: 0px 50px 14px 50px;
  border-radius: 6px;
  border: 1px solid $mid-gray;
  padding: 10px;

  @include for-xs-only {
    margin: 0px 20px 14px 20px;
    height: 100px;
  }
}
