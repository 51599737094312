// ----------------------------------------
// Responsive Layout Mixins
// ----------------------------------------

// --- EXTRA SMALL DEVICES ---
// Extra small devices and down (portrait phones, anything less than 576px)
@mixin for-xs-only {
  @media (max-width: 575px) {
    @content;
  }
}
// Exclude extra small devices
@mixin exclude-xs {
  @media (min-width: 576px) {
    @content;
  }
}

// --- SMALL DEVICES ---
// Small devices only (landscape phones, etc)
@mixin for-sm {
  @media (min-width: 576px) and (max-width: 767px) {
    @content;
  }
}
// Small devices and down
@mixin for-sm-down {
  @media (max-width: 767px) {
    @content;
  }
}
// Anything larger than small devices
@mixin for-sm-up {
  @media (min-width: 768px) {
    @content;
  }
}

// --- MEDIUM DEVICES ---
// Medium devices only (tablets, etc)
@mixin for-md {
  @media (min-width: 768px) and (max-width: 991px) {
    @content;
  }
}
// Medium devices and down
@mixin for-md-down {
  @media (max-width: 991px) {
    @content;
  }
}
// Amything larger than medium devices
@mixin for-md-up {
  @media (min-width: 992px) {
    @content;
  }
}

// --- LARGE DEVICES ---
// Large devices only (desktops, etc)
@mixin for-lg {
  @media (min-width: 992px) and (max-width: 1199px) {
    @content;
  }
}
// Large devices and down
@mixin for-lg-down {
  @media (max-width: 1199px) {
    @content;
  }
}
// Anything larger than large devices
@mixin for-lg-up {
  @media (min-width: 992px) {
    @content;
  }
}

// --- EXTRA LARGE DEVICES ---
// Extra large devices and up (extra large desktops and up)
@mixin for-xl {
  @media (min-width: 1200px) and (max-width: 1399px) {
    @content;
  }
}
// Extra large devices and down
@mixin for-xl-down {
  @media (max-width: 1399px) {
    @content;
  }
}
// Anything larger than extra large devices
@mixin for-xl-up {
  @media (min-width: 1200px) {
    @content;
  }
}
