// ----------------------------------------
// Moderna Mindsets Assessment Styles
//       --- ASSESSMENT SURVEY --
// ----------------------------------------

// ASSESSMENT SURVEY - GENERAL LAYOUT
.assessment-survey {
  h2 {
    font-size: 30px;
    line-height: 30px;
    color: $soft-black;
    @include for-xs-only {
      margin-top: 40px;
    }
  }
}

.assessment-survey--header {
  background-color: $white;
  padding: 30px 0px 10px 0px;
  box-shadow: 0 0.1rem 0.5rem rgba(0, 0, 0, 0.15);
  position: fixed;
  top: 48px;
  left: 0;
  right: 0;
  z-index: 1;

  @include for-md-down {
    position: relative;
    top: 0;
  }
}
.assessment-survey--header > div {
  @extend .container;
  position: relative;

  .assessment-instructions {
    font-size: 22px;
    line-height: 30px;
    color: $soft-black;
    margin: 20px 0px;

    @include for-xs-only {
      font-size: 18px;
      line-height: 24px;
    }

    li {
      font-size: 22px;
      @include for-xs-only {
        font-size: 18px;
      }
    }

    strong {
      font-size: 22px;
      font-weight: bold;
      @include for-xs-only {
        font-size: 18px;
      }
    }
  }

  // ASSESSMENT NAV STEPS 1--2--3
  .assessment-nav-steps {
    position: absolute;
    top: 6px;
    right: 0;
    width: 270px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-size: 12px;
    ul {
      display: flex;
      list-style: none;
      margin: 0px;
      padding-left: 20px;

      &:after {
        content: "";
        width: 90px;
        border-top: 2px dashed $moderna-orange;
        position: absolute;
        right: 28px;
        top: 10px;
        z-index: -1;
      }

      li {
        margin-right: 20px;
        text-align: center;
      }

      li > a {
        background-color: $pale-gray;
        color: $mid-gray;
        width: 24px;
        height: 24px;
        border-radius: 24px;
        font-size: 12px;
        display: flex;
        align-items: center;
        justify-content: center;

        &.router-link-active {
          background-color: $moderna-orange;
          color: $soft-black;
        }

        &:last-child {
          margin-right: 0px;
        }
      }
    }
  }
}

.assessment-survey--rows {
  @extend .container;
  padding: 20px 0px;
  margin-top: 230px;

  @include for-lg {
    margin-top: 260px;
  }

  @include for-md-down {
    margin-top: 0px;
  }

  .assessment-statement {
    background-color: $pale-gray;
    margin-bottom: 14px;
    padding: 10px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include for-sm-down {
      display: block;
    }

    > div {
      font-size: 16px;
    }

    > div:first-of-type {
      max-width: 80%;
      padding-left: 28px;
      text-indent: -28px;

      @include for-lg {
        max-width: 70%;
      }

      @include for-md-down {
        max-width: 66%;
        padding-left: 24px;
        text-indent: -28px;
      }
      @include for-sm-down {
        display: block;
        max-width: 100%;
        padding-left: 20px;
        text-indent: -18px;
        padding-bottom: 8px;
      }
    }

    > div:nth-of-type(2) {
      @include for-sm-down {
        text-align: left;
        padding-left: 20px;
      }
    }
  }

  .assessment-statement--label {
    @extend .btn;
    font-size: 12px;
    font-weight: bold;
    border-radius: 13px;
    padding: 3px 20px;
    border: 1px solid $access-blue;
    color: $access-blue;
    background-color: $white;
    &:hover {
      color: $access-blue;
    }
    &.selected {
      background-color: $deep-blue;
      border: 1px solid $deep-blue;
      color: $white;
    }
    &.disabled {
      border: 1px solid $pale-gray;
      color: $mid-gray;
    }
  }
}

.assessment-survey--footer {
  background-color: $deep-blue;
  color: $white;
}

.assessment-survey--footer > div {
  @extend .container;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 120px;

  @include for-xs-only {
    display: block;
    padding: 1px 20px 20px 20px;
  }

  .assessment-instructions {
    font-size: 22px;
    line-height: 30px;
    color: $white;
    margin: 20px 0px;
    padding-right: 10px;

    @include for-xs-only {
      padding-right: 0px;
    }

    strong {
      font-size: 22px;
      font-weight: bold;
    }
  }
}
