// ----------------------------------------
// Banner Styles
// ----------------------------------------

// Could also create <color> properties
@property --gradAngel {
  syntax: '<angle>';
  inherits: false;
  initial-value: 90deg;
}
@property --gradPoint1 {
  syntax: '<percentage>';
  inherits: false;
  initial-value: 20%;
}
@property --gradPoint2 {
  syntax: '<percentage>';
  inherits: false;
  initial-value: 80%;
}

.dash-banner {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin: 1rem 0px;
	padding: 1rem;
	color: $white;
	border-radius: 0.5rem;
	--gradAngel: 90deg; // Firefox needs these defined here
	--gradPoint1: 20%; // Firefox needs these defined here
	--gradPoint2: 80%; // Firefox needs these defined here
	background: $banner-red;
	background: linear-gradient(var(--gradAngel), $banner-blue var(--gradPoint1), $banner-red var(--gradPoint2));
  transition: --gradPoint1 0.25s, --gradPoint2 0.25s, filter 0.75s;

	@include for-sm-down {
		flex-direction: column;
		--gradAngel: 180deg;
	}

	.escape-banner {
		width: 33%;
		margin-top: -3rem;
		margin-bottom: -3rem;

		@include for-sm-down {
			width: 100%;
			margin-bottom: .5rem;
		}
	}

	svg {
		width: 22px;
		height: 22px;
		fill: $white;
	}

	&:hover,
	&:focus {
		color: $white;
		--gradPoint1: 55%;
  	--gradPoint2: 95%;

		svg {
			fill: $white;
			transform: scale(1.25);
		}
	}
}
