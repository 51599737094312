// ----------------------------------------
// Content Styles
// ----------------------------------------

.content-page {}

.content-keyresources {
	@extend .col-lg-4;
	@extend .my-3;

  .content-keyresources--card {
    @extend .card;
    height: 100%;
    color: $soft-black;
    border: 2px solid $link-color;
    border-radius: 6px;

    .card-img-overlay--wrapper {
      position: relative;
			height: 130px;
    }

		img, .card-img {
			width: 100%;
			height: 100%;
		  object-fit: cover;
			border-top-right-radius: 3px;
      border-top-left-radius: 3px;
      border-bottom-right-radius: 0px;
      border-bottom-left-radius: 0px;
		}

    .card-img-overlay {
      border-bottom-right-radius: 0px;
      border-bottom-left-radius: 0px;
			background: rgba(0,0,0,.7);
      background: linear-gradient(0deg, rgba(0,0,0,1) 10%, rgba(0,0,0,0.75) 25%, rgba(255,255,255,0) 60%);
		}

    .card-badge {
      position: absolute;
      top: 1rem;
      left: 1rem;
    }

    .card-title {
      display: inline-block;
      position: absolute;
      bottom: .5rem;
      left: 1rem;
      color: $white;

			// line-clamp
			display: -webkit-box;
		  -webkit-line-clamp: 2;
		  -webkit-box-orient: vertical;
		  overflow: hidden;
    }

		.card-body {
			@include for-sm-up {
				height: 95px;
			}
		}

		// line-clamp
		.line-clamped {
			display: -webkit-box;
		  -webkit-line-clamp: 3;
		  -webkit-box-orient: vertical;
		  overflow: hidden;
		}

    p {
      margin-bottom: 0px;
    }

    &:hover,
		&:focus {
			border-color: $soft-black;

			.card-badge {
				color: $white;
				background-color: $soft-black;
			}
		}
  }
}
