// ----------------------------------------
// Content Custom Styles
// ----------------------------------------

.content-custom-page {

	h1 strong, h1 b, .h1 strong, .h1 strong {
		@include arial-reg-34px;
	}
	h2 strong, h2 b, .h2 strong, .h2 b {
		@include arial-reg-30px;
	}

	.title-welcome {
		padding: 3rem;
		background-color: $light-blue;

		@include for-md-down {
			margin-bottom: 0px;
			padding: 3rem;
		}

		h2, h3, h4 {
			margin-top: 0px;
		}
		h2, h2 strong, h2 b {
			@include arial-reg-34px;
		}
	}

	.the-mindsets-card-wrapper {
		@include for-md-down {
			margin-bottom: 4rem;
		}
	}

	.the-mindsets-card {
		@extend .card;
		display: flex;
		height: 100%;
		@include for-md {
			min-height: 130px;
		}
		border: 3px solid $moderna-orange;
  	background-repeat: no-repeat;
		background-position: right bottom;
		background-size: contain;
		@include for-lg {
			background-size: 50%;
			background-position: right top;
		}

		.card-body {
			display: flex;
			flex-direction: column;

			h2 {
				width: 60%;
				line-height: normal;

				@include for-xl-up {
					font-size: 27px;
				}
				@include for-lg {
					width: 65%;
					font-size: 23px;
				}
			}
			p {
				margin-bottom: 0px;
			}
		}

		.card {
			margin-top: auto;
			margin-bottom: -4rem;
			border: 3px solid $moderna-orange;

			@include for-xl-up {
				min-height: 99px;
			}
			@include for-md-down {
				margin-bottom: -3rem;
			}

			.card-body {
				display: flex;
				justify-content: center;
			}
		}

		&.mindsets-red,
		&.mindsets-red .card {
			border-color: $moderna-red;
		}
		&.mindsets-green,
		&.mindsets-green .card {
			border-color: $moderna-green;
		}
		&.mindsets-light-blue,
		&.mindsets-light-blue .card {
			border-color: $another-mid-blue;
		}
		&.mindsets-cyan,
		&.mindsets-cyan .card {
			border-color: $custom-cyan;
		}
		&.mindsets-teal,
		&.mindsets-teal .card {
			border-color: $custom-teal;
		}
		&.mindsets-purple,
		&.mindsets-purple .card {
			border-color: $custom-purple;
		}
		&.mindsets-salmon,
		&.mindsets-salmon .card {
			border-color: $custom-salmon;
		}
		.card {
			border-width: 1px;
		}
	}

	a.card {
		border-color: $access-blue;

		&:hover,
		&:focus {
			border-color: $soft-black;

			.card {
				border-color: $soft-black;
			}
		}

		.card {
			.card-body {
				color: $soft-black;
			}
		}
	}

	// callout with thick-bordered cards
	.wrapping-thickBorders {
		.card {
			border-width: 9px;

			.card-image img {
				border-top-right-radius: 0px;
    		border-top-left-radius: 0px;
			}
		}
	}

	// callout with fullwidth-bg & borderless cards
	.wrapping-fullwidthBG {
		position: relative;

		&:before {
	    content: "";
	   	z-index: -1;
	    position: absolute;
	    left: 50%;
	    right: 50%;
	    width: 100vw;
	    height: 100%;
	    margin-left: -50vw;
	    margin-right: -50vw;
			background-color: $light-blue;
		}

		.dash-whatsnew--secondary {
			margin-top: 1.5rem;
		}

		.card {
			border: 0px;
		}

		.card-footer {
			background-color: $white;
			border: 0px;
		}
	}

	// callout right truncated BG color
	.row-callout-right {
		position: relative;
		padding: 3rem 0px 3rem 3rem;

		&:before {
	    content: "";
	   	z-index: -1;
	    position: absolute;
			height: 60%;
	    width: 100vw;
	    right: -50%;
			left: 10px;
			background-color: $light-blue;

			@include for-md {
				height: 80%;
				top: 0;
			}
			@include for-sm-down {
				height: 100%;
  			left: calc(-1 * (100vw - 100%)/2);
			}
		}

		@include for-sm-down {
			padding-left: 1rem;
			padding-right: 1rem;
		}

		.dash-whatsnew--secondary {
	    flex: 0 0 auto;
	    width: 33.333%;

			@include for-lg-up {
				margin-bottom: 0px !important;
			}
			@include for-md {
				margin-bottom: 0px !important;
			}
			@include for-sm-down {
				width: 100%;
			}

			.card {
				.card-footer {
					background-color: $white;
					border: 0px;
				}
			}
		}

		&.workshops {
			.dash-whatsnew--secondary {
				@include for-lg-up {
					width: 39%;
				}

				@include for-md {
					width: 33.333%;
				}
			}
		}
	}

	// callout left exansive BG color
	.row-callout-left {
		position: relative;

		&:before {
	    content: "";
	   	z-index: -1;
	    position: absolute;
			height: 100%;
	    width: 100vw;
			right: 10px;
			background-color: $light-blue;

			@include for-sm-down {
	  		left: calc(-1 * (100vw - 100%)/2);
			}
		}

		.custom-img-placement {
			margin-top: 1rem;

			@include for-lg-up {
				position: absolute;
				left: -5rem;
				max-width: 120%;
			}
			@include for-md {
				padding: 0px 10rem;
				margin-bottom: 1rem;
			}
			@include for-sm-down {
				padding: 0px 4rem;
				margin-bottom: 1rem;
			}
		}

		.checkerboard {
		  display: grid;
		  grid-template-columns: repeat(3, 1fr);
		  grid-template-rows: auto;
		  gap: 1em 1em;
		  width: 100%;
		  height: 100%;
			align-items: center;

			@include for-md {
				grid-template-columns: repeat(2, 1fr);
			  grid-template-rows: auto;
			}
			@include for-sm-down {
				grid-template-columns: 1fr;
			  grid-template-rows: auto;
			}

			.checkerboard-item {
				display: flex;
				align-items: center;
				height: 100%;
				padding: 1rem;
				background-color: $lightest-blue;

				@include for-lg-up {
					min-height: 165px;
				}

				h5 strong, .h5 strong {
					@include arial-reg-19px;
				}

				&.bg-color-1 {
					background-color: $almost-mid-blue;
				}
				&.bg-color-2 {
					background-color: $mid-blue;
				}
				&.bg-color-3 {
					background-color: $sky-blue;
				}
			}
		}
	}

	.section-header {
		h2, h3, h4, p {
			margin-bottom: 0px;
		}
	}
}
