// ----------------------------------------
// Moderna Mindsets Assessment Styles
//       --- ASSESSMENT MINDSETS --
// ----------------------------------------

// ASSESSMENT MINDSETS - STRENGTHS & STRETCHES
.assessment-survey.strengths-stretches {
  .assessment-survey--rows {
    margin-top: 160px;

    @include for-lg {
      margin-top: 188px;
    }

    @include for-md-down {
      margin-top: 0px;
    }

    .assessment-statement {
      background-color: $light-blue;
      border: 1px solid $light-blue;
      border-bottom: none;
      position: relative;

      > div:first-of-type {
        max-width: 60%;
        
        @include for-md-down {
          max-width: 50%;
        }
        @include for-sm-down {
          display: block;
          max-width: 100%;
          padding-left: 20px;
          text-indent: -18px;
          padding-bottom: 8px;
        }
      }

      &.learn-more-open {
        border: 1px solid $mid-blue;
        border-bottom: none;
      }

      .learn-more-link {
        padding-right: 20px;
        cursor: pointer;

        @include for-lg {
          padding-right: 0px;
        }

        @include for-sm-down {
          position: absolute;
          right: 8px;
        }

        &:hover {
          color: $access-blue;
        }

        svg {
          width: 16px;
          height: 16px;
          fill: $access-blue;
        }
      }

      .selection-wrapper {
        min-width: 250px;
        text-align: right;
        font-size: 16px;

        @include for-md-down {
          min-width: 216px;
        }
        @include for-sm-down {
          text-align: left;
          width: 100%;
        }

        strong {
          font-size: 16px;
        }

        svg {
          width: 24px;
          height: 24px;
          fill: $access-blue;
        }
      }
    }

    .manager-notes {
      border: 1px solid $mid-blue;
      border-top: none;
      margin-top: -14px;
      margin-bottom: 14px;
      padding: 14px 40px 20px 40px;
      &.learn-more-visible {
        border-top:none; border-bottom: none;
      }
      input[type="text"] {
        border-radius: 6px;
        border: 1px solid $mid-gray;
        padding: 10px;
        width: 80%;
      }

    }

    .learn-more-text {
      border: 1px solid $mid-blue;
      border-top: none;
      margin-top: -14px;
      margin-bottom: 14px;
      padding: 14px 40px 2px 40px;

      p {
        max-width: 60%;
        margin-left: 4px;

        @include for-md {
          max-width: 50%;
        }

        @include for-sm-down {
          max-width: 100%;
        }
      }
    }
  }
}
