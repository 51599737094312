// ----------------------------------------
// Dashboard Styles
// ----------------------------------------

.card-badge {
	@extend .badge !optional;
	@include arial-reg-12px;
	font-weight: normal;
	color: $soft-black;
	border-radius: 9px;
	background-color: $pale-gray;
}
