// ----------------------------------------
// Moderna Mindsets Assessment Styles
//       --- ASSESSMENT LAUNCH --
// ----------------------------------------

// ASSESSMENT LAUNCH SCREEN
.assessment-launch {
  @extend .container;
  display: flex;
  align-items: center;
  margin-top: 40px;

  @include for-md-down {
    display: block;
  }

  h2 {
    font-size: 30px;
    line-height: 36px;
    color: $soft-black;

    strong {
      font-size: 30px;
      font-weight: bold;
    }

    span {
      font-size: 30px;
    }
  }
}

.assessment-launch--side {
  width: 50%;

  @include for-md-down {
    width: 100%;
  }

  &:nth-of-type(1) {
    padding: 50px;
    @include for-md-down {
      padding: 0px;
    }
  }

  &:nth-of-type(2) {
    margin-top: 200px;

    @include for-md-down {
      margin-top: 0px !important;
      margin-top: 0px !important;
      background-color: #ffffffd9 !important;
      border-radius: 10px;
      position: relative;
      padding: 20px;
    }

    @media only screen and (max-height: 810px) {
      margin-top: 100px;
    }
    @media only screen and (max-height: 700px) {
      margin-top: 50px;
    }
    @media only screen and (max-height: 600px) {
      margin-top: 0px;
      margin-bottom: 60px;
    }

    h2:nth-of-type(2) {
      line-height: 30px;
    }
  }

  .assessment-video-bg {
    position: fixed;
    right: 53%;
    bottom: 55px;
    min-width: 100%;
    min-height: 100%;
    background-color: $mid-blue;
    @include for-md-down {
      background-color: transparent;
    }
  }

  .launch-video {
    position: fixed;
    right: 53%;
    bottom: 55px;
    min-width: 100%;
    min-height: 100%;

    @include for-md-down {
      position: fixed;
      right: 0;
      top: 0;
      left: 0;
      bottom: 0;
      min-width: 100%;
      min-height: 100%;
      z-index: 0;
      opacity: 0.2;
    }

    @media only screen and (max-height: 600px) {
      bottom: 0;
      z-index: 0;
    }
  }

  .welcome-message,
  .email-message {
    font-size: 14px;
    line-height: 20px;
    color: $soft-black;
    margin-bottom: 20px;
    padding-right: 100px;

    @include for-lg-down {
      padding-right: 0px;
    }
  }

  .email-message input {
    width: 100%;
    border: 1px solid $access-blue;
    border-radius: 5px;
    min-height: 40px;
    padding: 0px 10px;
  }

  img {
    @include for-md-down {
      max-width: 400px;
      height: auto;
      width: 100%;
      margin: 0 auto;
      display: block;
    }
  }
}

// ASSESSMENT REQUEST ACCESS
.assessment-request {
  @extend .container;
  display: flex;
  align-items: center;
  margin-top: 40px;

  @include for-md-down {
    display: block;
  }

  h2 {
    font-size: 30px;
    line-height: 36px;
    color: $soft-black;

    strong {
      font-size: 30px;
      font-weight: bold;
    }

    span {
      font-size: 30px;
    }
  }
}

.assessment-request--side {
  width: 50%;

  @include for-md-down {
    width: 100%;
  }

  &:nth-of-type(1) {
    padding: 50px;
  }

  &:nth-of-type(2) {
    h2:nth-of-type(2) {
      line-height: 30px;
    }
  }

  .welcome-message {
    font-size: 14px;
    line-height: 20px;
    color: $soft-black;
    margin-bottom: 20px;
  }

  img {
    @include for-md-down {
      max-width: 400px;
      height: auto;
      width: 100%;
      margin: 0 auto;
      display: block;
    }
  }
}
