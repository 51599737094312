// ----------------------------------------
// Header Styles
// ----------------------------------------

// Padding for fixed nav
main {
  padding-top: 60px;

  @include for-md-down {
    padding-top: 43px;
  }
}

.navbar {
  @extend .shadow;
  @extend .p-0;

  &.navbar-light {
    background-color: $white;

    .nav-link {
      @extend .mx-2;
      color: $link-color;

      &:hover,
      &:focus {
        color: $link-hover-color;
      }
    }
  }

  .navbar-brand {
    img {
      width: 180px;
      min-width: 180px;
      height: 34px;
    }
  }

  // change the order of the mobile nav, so that search is first.
  .navbar-collapse.collapsing,
  .navbar-collapse.show {
    @include for-md-down {
      display: flex;
      flex-direction: column;

      .navbar-nav {
        width: 100%;
        order: 2;
      }

      .breakout {
        order: 1;
      }
    }
  }

  .dropdown-menu {
    @extend .shadow;
    padding: 0.5rem 0px;
    border-color: $access-blue;

    .dropdown-item {
      padding: 0.5rem 1rem;
      color: $link-color;

      &:hover,
      &:focus {
        color: $link-hover-color;
        background-color: unset;
      }
    }
  }

  .breakout {
    border-radius: 30px 0px 0px 30px;
    padding: 1rem 0 1rem 1rem;
    background-color: $moderna-blue;

    @include for-xl-up {
      width: 90vw;
      margin-left: 10vw;
    }

    @include for-lg {
      width: 95vw;
      margin-left: 0px;
    }

    @include for-md-down {
      width: 100%;
      margin-bottom: 1rem;
      padding: 1rem;
      border-radius: 30px;
    }
  }

  form {
    width: 25%;

    @include for-md-down {
      width: 100%;
    }

    #search-input {
      padding-right: 1rem;
      border: 0px;
      border-radius: 15px 0px 0px 15px;
    }

    #search-input-button {
      z-index: 4;
      margin-left: -1rem;
      border: none;
      border-radius: 15px;
      background-color: $link-color;
      color: $white;

      svg {
        width: 16px;
        height: 16px;
        fill: $white;
      }

      &:hover,
      &:focus {
        background-color: $link-hover-color;
      }
    }
  }
}

#gridWrapperNav {
  @include for-sm-up {
    display: grid;
    grid-template-columns: 1fr min-content;
    grid-template-rows: auto;
    gap: 0px 0px;

    .gridNav--right {
      width: 0px;
      transition: all 0.5s ease-in-out;
    }

    &.mycorner--open {
      .gridNav--right {
        width: 300px;
      }
    }
  }
}
