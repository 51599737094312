// ----------------------------------------
// Quick Search Styles
// ----------------------------------------

#search-app {
  width: 100%;
}

.dropdown-menu {
  &.dropdown-quicksearch {
    width: 100%;
    --dropdown-zero-y: 0px;
    --dropdown-left-offset: 1.5rem;
    margin-top: 35px;
    padding-bottom: var(--dropdown-zero-y);

    /* ===== Scrollbar CSS ===== */
    /* Firefox */
    * {
      scrollbar-width: auto;
      scrollbar-color: #E8E8E8 $white;
    }

    /* Chrome, Edge, and Safari */
    *::-webkit-scrollbar {
      width: 16px;
    }

    *::-webkit-scrollbar-track {
      background: $white;
    }

    *::-webkit-scrollbar-thumb {
      background-color: #E8E8E8;
      border-radius: 10px;
      border: 3px solid $white;
    }

    .dropdown-quicksearch--scroll {
      overflow-y: auto;
      max-height: 45vh;

      @include for-md-down {
    		max-height: 35vh;
    	}

      li:not(.dropdown-header){
        margin-bottom: 10px;

        &:hover,
        &:focus {
          background-color: $pale-blue;

          .badge {
            background-color: $soft-black;
          }
        }
      }

      li.two-columns {
        .dropdown-item {
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
        }
      }
    }

    .dropdown-header {
      p {
        @include arial-reg-12px;
        margin-bottom: var(--dropdown-zero-y);
        padding-bottom: var(--dropdown-zero-y);
        color: $soft-black;
      }
    }

    .dropdown-item {
      margin-bottom: var(--dropdown-zero-y);
      // padding: var(--dropdown-zero-y) var(--dropdown-left-offset);
      padding: 5px 10px 5px var(--dropdown-left-offset);
      white-space: normal;
      line-height: 14px;

      @include for-md-down {
    		padding: 10px 10px 10px var(--dropdown-left-offset);
    	}
    }

    .dropdown-text {
      @include arial-reg-10px;
      text-align: right;
      color: $dark-gray;
      margin-top: 2px;
      padding: var(--dropdown-zero-y) var(--dropdown-left-offset);

      strong {
        @include arial-reg-10px;
      }
    }
    li.two-columns .dropdown-text {
      min-width: 100px;
      width: 100px;
      max-width: 100px;
      margin: 0px;
      padding: 0px 0px 0px 5px;

      .badge {
        font-size: 10px;
        background-color: $access-blue;
      }
    }

    .btn-sharepoint,
    .btn-all-results {
      width: 100%;
      margin-bottom: 0px;
      padding: .5rem;
      text-align: center;
      color: $white;
      font-weight: 700;
      background-color: $link-color;

      &:hover,
      &:focus {
        color: $white;
        background-color: $link-hover-color;
      }
    }

    .btn-sharepoint {
      // margin-top: 10px;
      background-color: $deep-blue;
    }
  }
}
