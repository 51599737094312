// ----------------------------------------
// Onboarding Styles
// ----------------------------------------

.onboarding-page {

	.title-welcome {
		margin: 1rem 0;
		padding: 5rem;
		background-color: $light-blue;

		@include for-md-down {
			margin-bottom: 0px;
			padding: 3rem;
		}
	}

	h1 {
		color: $access-blue;
	}

	.row-getstarted {
		position: relative;
		background: $pale-gray;

		&:before {
	    content: "";
	   	z-index: -1;
	    position: absolute;
			height: 100%;
	    width: 100vw;
	    right: -50%;
			background-color: $pale-gray;
		}

		@include exclude-xs  {
			border-radius: 50px 0px 0px 50px;
		}
		@include for-sm-down {
			padding-left: 1rem;
			padding-right: 1rem;
		}

		img {
			margin-top: -1.5rem;

			@include for-lg-up {
				margin-left: 3rem;
			}
			@include for-sm-down {
				margin-bottom: 1.5rem;
			}
		}

		.launch-btn {
			@include arial-reg-16px;
			padding: 1rem;
			border-radius: 50px;

			@include for-sm-down {
				margin-bottom: 1.5rem;
			}

			svg {
				width: 16px;
				height: 20px;
				margin-left: .5rem;
				fill: $white;
			}

		  &:hover,
		  &:focus {
		    color: $white;
		    border-color: $soft-black;
		    background-color: $link-hover-color;
		  }
		}
	}
}
