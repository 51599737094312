// ----------------------------------------
// Search Styles
// ----------------------------------------

.search-page {
  .container-fluid {
    @extend .mb-3;
    @extend .py-3;
    background-color: $mid-blue;

    h1, .h6 {
      margin-top: 0px;
      margin-bottom: 0px;
    }

    .h6 {
      margin-top: 5px;
    }
  }

  .dash-community--card {
    @include for-lg-up {
      min-height: 200px;
      max-height: 275px;
    }
  }
}

.search-page--row {
  @extend .row;
  --bs-gutter-x: 5rem;
}

.search-page--results,
.search-page--results--sharepoint {
  @extend .row;
  @extend .my-3;
  --bs-gutter-y: 0px;
  --bs-gutter-x: 3rem;
  position: relative;

  .btn-sharepoint {
    border-radius: 50px;
    text-align: center;
    color: $white;
    font-weight: 700;
    background-color: $deep-blue;

    &:hover,
    &:focus {
      color: $white;
      background-color: $link-hover-color;
    }
  }
}

.search-page--results--sharepoint {
  align-items: center;

  p, h6 {
    margin-bottom: 0px;
  }
}

.search-page--type {
  @include arial-reg-12px;
}

.search-page--link {
  @extend .row;
  @extend .g-3;
  @extend .mb-4;

  svg {
    width: 12px;
    height: 12px;
    fill: $dark-gray;
  }

  h5, p {
    margin-bottom: 0px;
  }

  p {
    color: $soft-black;
  }

  &:hover,
  &:focus {
    svg {
      fill: $link-hover-color;
    }
  }
}

.search-page--pagination {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;

  a {
    @extend .mb-4;

    svg {
      margin-top: -3px;
      width: 12px;
      height: 12px;
      fill: $link-color;
    }

    &:hover,
    &:focus {
      svg {
        fill: $link-hover-color;
      }
    }

    &.pagination-previous {
      @extend .me-auto;

      svg {
        transform: rotate(180deg);
      }
    }

    &.pagination-next {
      @extend .ms-auto;
    }
  }
}
