// ----------------------------------------
// Moderna Mindsets Assessment Styles
//       --- HELPERS & EXTRAS --
// ----------------------------------------

// ASSESSMENT BUTTON PRIMARY - SMALL BLUE PILL
.assessment-btn-primary {
  @extend .btn;
  background-color: $access-blue;
  color: $white;
  font-size: 12px;
  font-weight: bold;
  border-radius: 13px;
  padding: 3px 20px;
  &:hover {
    color: $white;
    background-color: lighten($access-blue, 5%);
  }
}

.assessment-btn-secondary {
  @extend .btn;
  background-color: $white;
  color: $access-blue;
  border: 1px solid $access-blue;
  font-size: 12px;
  font-weight: bold;
  border-radius: 13px;
  padding: 3px 20px;
  &:hover {
    color: lighten($access-blue, 5%);
  }
}

// ASSESSMENT BUTTON BIG - LARGE WHITE PILL
.assessment-btn-big {
  @extend .btn;
  background-color: $white;
  color: $access-blue;
  font-size: 19px;
  font-weight: bold;
  border-radius: 25px;
  padding: 10px 20px;
  min-width: 162px;
  &:hover {
    color: lighten($access-blue, 5%);
    background-color: $white;
  }
  &.inactive,
  &[disabled] {
    background-color: $mid-gray;
    color: $white;
    pointer-events: none;
    opacity: 1;
  }

  @include for-xs-only {
    width: 100%;
  }

  span {
    font-size: 19px;
    font-weight: bold;
  }
}

// ASSESSMENT BUTTON SMALL - MINI BLUE PILL
.assessment-btn-small {
  @extend .btn;
  background-color: $access-blue;
  color: $white;
  font-size: 12px;
  font-weight: bold;
  border-radius: 6px;
  padding: 0px 10px;
  &:hover {
    color: $white;
    background-color: lighten($access-blue, 5%);
  }
}

// ASSESSMENT NAVBAR
.assessments-navbar {
  @extend .navbar;
  @extend .navbar-light;
  @extend .fixed-top;
  height: 48px;
  border-bottom: 2px solid $moderna-blue;
  box-shadow: none !important;
  display: flex;
  align-content: center;

  h1 {
    font-size: 26px;
    margin: 0px;

    strong,
    span {
      font-size: 26px;
    }

    @include for-sm-down {
      font-size: 12px;
      strong,
      span {
        font-size: 12px;
      }
    }
  }

  h2 {
    font-size: 22px;
    margin: 0px;

    @include for-sm-down {
      font-size: 12px;
    }
  }
}

// ASSESSMENT FOOTER
.assessments-footer {
  border-top: 1px solid $black;
  background-color: $white;
  z-index: 1;

  img {
    width: 150px;
    margin: 10px 0px;
  }
}

// ASSESSMENT ALERT
.assessment-alert {
  border: 1px solid $dark-gray;
  border-radius: 10px;
  padding: 20px;
  margin: 30px 0px 60px 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
}
