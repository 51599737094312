// ----------------------------------------
// MyCorner Styles
// ----------------------------------------

.grid--wrapper {
    display: grid;
    gap: 0px 0px;
    width: 100%;
    height: 100%;
    min-height: 100vh;
    overflow: hidden;
    transition: all 0.5s ease-in-out;

    @include for-sm-up {
      grid-template-columns: 1fr min-content;
      grid-template-rows: auto;
    }

    @include for-sm-down {
      grid-template-columns: 1fr;
      grid-template-rows: min-content 1fr;
    }

    .grid--left {
        @extend .position-relative;

        @include for-sm-up {
          order: 0;
        }

        @include for-sm-down {
            order: 1;
        }
    }

    .mycorner--btn {
        svg {
            @include for-sm-down {
                transform: rotate(180deg) scaleX(-1);
            }
        }
    }

    .grid--right {
        background-color: $pale-gray;
        transition: all 0.5s ease-in-out;
        width: 0px;

        @include for-sm-up {
            height: 100%;
        }

        @include for-sm-down {
            transition: unset;
            height: 0px;
            margin-top: -100vh;
            order: 0;

            .mycorner--content {
                display: none;
            }
        }

        .mycorner--content {
            padding: 1rem;

            @include for-sm-up {
                width: 300px;
                height: 100%;
            }

            @include for-sm-down {
                width: 100%;
                height: auto;
            }

            h2 {
                color: $deep-blue;
                margin-top: 1.25rem;
            }

            .mycorner--content--box {
                @extend .container;
                margin: 0;
                padding: 1rem;
                border-radius: 6px;
                border: 1px solid $mid-gray;
                background-color: $white;

                .mycorner--content--title {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    .form-switch {
                        cursor: pointer;

                        .form-check-input {
                            cursor: pointer;
                            &:checked {
                                background-color: $moderna-blue;
                                border-color: $moderna-blue;
                            }
                        }

                        label {
                            cursor: pointer;
                            min-width: 62px;
                        }
                    }
                }

                a,
                .custom-link-add,
                .mylink-add {
                    cursor: pointer;
                    color: $link-color;

                    svg {
                        fill: $link-color;
                        width: 10px;
                        height: 10px;
                    }

                    &:hover,
                    &:focus {
                        color: $link-hover-color;
                        svg {
                            fill: $link-hover-color;
                        }
                    }
                }
                .custom-link-add,
                .mylink-add {
                  margin-top: .5rem;
                }
                .mylink-add {
                    svg {
                        width: 15px;
                        height: 15px;
                    }
                }

                .manageApps {
                    display: block;
                    margin-top: 1rem;
                }

                p {
                    color: $dark-gray;
                    margin-bottom: 0px;
                }

                .match-h4 {
                    margin-top: -3px;
                    width: 22px;
                    height: 22px;
                    fill: $moderna-green;
                }
            }

            .mycorner--apps-logo {
                @extend .card;
                height: 60px;
                padding: .5rem;
                border-radius: 6px;
                border: 1px solid $link-color;
                display: flex;
                justify-content: center;
                align-items: center;

                @include for-sm-down {
                    align-items: flex-start;
                }

                svg {
                    fill: $link-color;
                }

                img {
                    max-height: calc(60px - .5rem);
                }

                &:hover,
                &:focus {
                    border-color: $link-hover-color;

                    svg {
                        fill: $link-hover-color;
                    }
                }

                &.handle {
                    position: relative;
                    cursor: grab;

                    svg {
                        width: 12px;
                        height: 12px;
                    }

                    .appDelete {
                        position: absolute;
                        top: -10px;
                        right: -10px;

                        svg.app-delete {
                            width: 25px;
                            height: 25px;
                            cursor: pointer;

                            fill: $mid-gray;

                            &:hover,
                            &:focus {
                                fill: $moderna-red;
                            }
                        }
                    }
                }
            }

            .mycorner--apps-link,
            .mycorner--apps-pageLink,
            .mycorner--apps-customLink {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin: .5rem 0;
                padding: .25rem 0;
                border-bottom: 1px solid $moderna-blue;

                &:last-child {
                    border-bottom: 0px;
                }

                .disabled {
                    pointer-events: none;
                    user-select: none;
                    opacity: 0.50;
                }
            }

            .mycorner--apps-pageLink,
            .mycorner--apps-customLink {
                &.hasHandle {

                  .handle {
                      cursor: grab;
                      color: $link-color;

                      svg {
                          fill: $link-color;
                          width: 12px;
                          height: 12px;
                          margin-right: 5px;
                      }

                      &:hover,
                      &:focus {
                          color: $link-hover-color;

                          svg {
                              fill: $link-hover-color;
                          }
                      }
                  }

                  svg.custom-link-delete {
                      margin-right: 0px;
                      margin-left: 10px;
                      width: 20px;
                      height: 20px;
                      cursor: pointer;

                      fill: $mid-gray;

                      &:hover,
                      &:focus {
                          fill: $moderna-red;
                      }
                  }
                }

                a {
                    display: flex;
                    align-items: flex-start;
                    width: calc(100% - 65px);

                    svg {
                        min-width: 12px;
                        min-height: 12px;
                        margin-top: 5px;
                        margin-right: 5px;
                    }
                }
            }
            .mycorner--apps-customLink {

              a {
                  width: calc(100% - 65px);
                }

                svg.custom-link-edit {
                    margin-top: 5px;
                    margin-right: 0px;
                    margin-left: 20px;
                    width: 10px;
                    height: 10px;
                    cursor: pointer;

                    fill: $mid-gray;

                    &:hover,
                    &:focus {
                        fill: $link-color;
                    }
                }

                svg.custom-link-delete {
                    margin-right: 0px;
                    margin-left: 10px;
                    width: 20px;
                    height: 20px;
                    cursor: pointer;

                    fill: $mid-gray;

                    &:hover,
                    &:focus {
                        fill: $moderna-red;
                    }
                }
            }
        }
    }

    &.mycorner--open {

        .grid--right {
            width: 100%;

            @include for-sm-up {
                width: 300px;
                order: 1;
            }

            @include for-sm-down {
                transition: margin-top 0.5s ease-in-out;
                width: 100%;
                height: auto;
                order: 0;
                margin-top: 0;

                .mycorner--content {
                    display: block;
                }

                .mycorner--content--box {
                    margin: 0 auto;
                }
            }
        }

        .mycorner--btn {
          svg {
              transform: rotate(90deg) scaleX(-1);

              @include for-sm-down {
                transform: rotate(0deg) scaleX(-1);
              }
          }
        }

        // Compensate for absolutely placed MyCorner BTN
        .col-compensator {
            padding-right: calc(var(--bs-gutter-x, 0.75rem) + 3rem);
        }
    }
}

// Compensate for absolutely placed MyCorner BTN
.col-compensator {
    transition: padding 0.5s ease-in-out;

    @include for-sm-down {
        padding-right: calc(var(--bs-gutter-x, 0.75rem) + 3rem);
    }
}

.mycorner--btn {
    position: absolute;
    z-index: 5;
    top: 25px;
    right: -105px;
    border-radius: 0px;
    transform: rotate(-90deg) translate(-32px, -61px);
    @include arial-reg-16px;
    color: $white;
    white-space: nowrap;
    border: 0px;
    background-color: $moderna-blue;

    svg {
        fill: $link-color;
        width: 24px;
        height: 24px;
        @extend .me-1;
        transform: rotate(90deg);
    }

    &:hover,
    &:focus {
        color: $white;
        background-color: $link-hover-color;

        svg {
            fill: $link-hover-color;
        }
    }

    // Hide .mycorner--btn on onboarding page
    .onboarding-page & {
        // display: none;
    }
}

.mycorner--closer {
    @include for-sm-up {
        display: none;
    }

    svg {
        width: 30px;
        height: 30px;
        fill: $link-color;
    }

    &:hover,
    &:focus {
        svg {
            fill: $link-hover-color;
        }
    }
}
