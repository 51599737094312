// ----------------------------------------
// Moderna Mindsets Assessment Styles
//    --- MANAGER / COACH VIEW --
// ----------------------------------------

// MANAGER/COACH DASHBOARD
.assessment-survey.manager-dashboard,
.assessment-survey.coach-dashboard {
  .assessment-survey--rows {
    margin-top: 0px;

    @include for-xs-only {
      padding: 20px;
    }

    h2 {
      font-size: 34px;
    }

    .dashboard-instructions {
      font-size: 16px;
      margin: 20px 0px;
    }
  }

  .manager-dashboard--table,
  .coach-dashboard--table {
    border: 1px solid $dark-gray;
    border-radius: 10px;
    padding: 20px;

    .table {
      border: 1px solid $light-gray;
      border-top: 6px solid $light-gray;
      border-bottom: 14px solid $light-gray;
      margin: 10px 0px;

      thead {
        background-color: $pale-gray;

        tr th {
          border-bottom: 1px solid $pale-gray;
          font-size: 12px;
        }

        &.search-bar {
          background: $light-gray;
          tr th {
            padding-bottom: 12px;
          }

          .search-bar--input {
            display: flex;
            align-items: center;

            label {
              font-size: 12px;
            }

            input {
              max-width: 230px;
              max-height: 20px;
              margin: 0px 10px;
              font-size: 12px;
            }
          }
        }
      }

      tbody {
        tr td {
          font-size: 14px;
          border-bottom: 1px solid $pale-gray;

          &.manager-cell {
            background-color: $assessments-pale-yellow;
          }
          &.participant-cell {
            background-color: $light-blue;
          }
        }
      }
    }
  }
}
