// ----------------------------------------
// Link & Button Styles
// ----------------------------------------


a {
  color: $link-color;
  text-decoration: none;

  &:hover,
  &:focus {
    color: $link-hover-color;
  }
}

.launch-btn,
.learnmore-btn {
  font-weight: 700;
  border-radius: 20px;
  border: 1px solid $link-color;
}

.launch-btn {
  margin-right: .5rem;
  color: $white;
  background-color: $link-color;

  &:hover,
  &:focus {
    color: $white;
    border-color: $soft-black;
    background-color: $link-hover-color;
  }
}

.learnmore-btn {
  color: $link-color;
  background-color: $white;

  &:hover,
  &:focus {
    color: $soft-black;
    border-color: $soft-black;
    background-color: $pale-gray;
  }
}

/* Hide focus styles if they're not needed, for example,
when an element receives focus via the mouse. */
*:focus:not(:focus-visible) {
  outline: 0;
}

/* Show focus styles on keyboard focus. */
*:focus-visible {
  outline: 3px solid rgba($moderna-orange, .5);
}

//  disabled states for cards/tiles
.disabled {
  &.card,
  a {
    pointer-events: none;
    user-select: none;
    color: $soft-black;
  }
}

.card.disabled,
.content-keyresources--card.disabled {
  border: 2px solid $mid-gray !important;
}
