// ----------------------------------------
// Filtering Styles
// ----------------------------------------

.app-filtering {
  @include arial-reg-12px;
  @extend .container-fluid;
  border-top: 1px solid $mid-gray;
  border-bottom: 1px solid $mid-gray;
  background-color: $light-blue;

  .container {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }

  .bubble {
    cursor: pointer;
    margin: 0.5rem 5px;
    padding: 1rem;
    border-radius: 20px;
    border: 1px solid $mid-gray;
    box-shadow: inset 0px 0px 0px 1px $white;
    box-sizing: border-box;
    background-color: $white;

    &:hover,
    &:focus {
      border: 1px solid $soft-black;
    }

    &[active] {
      font-weight: 700;
      color: $link-hover-color;
      border: 1px solid $moderna-blue;
      box-shadow: inset 0px 0px 0px 1px $moderna-blue;
    }
  }

  // filtering styles for app index filter bar
  .filter-bar {
    @extend .container;
    @extend .d-flex;
    @extend .flex-nowrap;

    @include for-lg-down {
      flex-wrap: wrap !important;
    }
  }
  .filtering-input {
    min-width: 270px;
    border-radius: 4px;
    background: $white;
    border: 1px solid $mid-gray;
    padding: 2px 10px;

    @include for-lg-down {
      margin: 6px 0px 0px 24px;
    }

    @include for-sm-down {
      margin: 10px 0px 2px 24px;
    }
  }

  .filtering-pills {
    @extend .w-100;
    @extend .d-flex;
    @extend .justify-content-between;
    padding: 6px 0px 6px 60px;

    @include for-xl-down {
      padding: 6px 0px 6px 20px;
    }

    @include for-md-down {
      flex-wrap: wrap;
      justify-content: flex-start !important;
    }

    .pill {
      cursor: pointer;
      margin: 0px 0px 0px 4px;
      padding: 10px 16px;
      border-radius: 20px;
      border: 1px solid $mid-gray;
      box-shadow: inset 0px 0px 0px 1px $white;
      box-sizing: border-box;
      background-color: $white;
      font-size: 12px;
      display: flex;
      align-items: center;

      &:hover {
        border: 1px solid $soft-black;
      }

      &[active] {
        font-weight: 700;
        color: $link-hover-color;
        border: 1px solid $moderna-blue;
        box-shadow: inset 0px 0px 0px 1px $moderna-blue;
      }

      @include for-xl {
        padding: 10px 10px;
      }
      @include for-md-down {
        margin: 0px 0px 4px 4px;
      }
    }
  }
}
