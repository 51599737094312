// ----------------------------------------
// Press Releases Styles
// ----------------------------------------

.dash-pressreleases {
	@extend .mt-2;
	@extend .py-3;
	transition: all 0.5s ease-in-out;

	--bs-bg-opacity: .60;
	background-color: rgba(47, 45, 45, var(--bs-bg-opacity));

	h2, h5, h6 {
		margin-bottom: 0px;
	}

	h2, .xl-single-character {
		color: $moderna-blue;
	}

	h5 {
		color: $white;
	}

	.card-body--seemore-text {
		color: $link-color;
	}

	.dash-pressreleases--card {
		@extend .card;
		@extend .h-100;
		color: $soft-black;
		border: 2px solid $moderna-blue;
		background-color: $white;

		.card-body {
			padding-bottom: 0px;
		}

		.card-text {
			@extend .tile-title;
			@include arial-reg-12px;
			line-height: 18px;
			margin-bottom: 0px;
		}

		.card-footer {
			@include arial-reg-12px;
			color: $dark-gray;
			border: none;
			background-color: $white;
		}

		.card-body--seemore {
			@extend .card-body;
			@extend .d-flex;
			padding-bottom: 1rem;
			flex-direction: column;
			justify-content: center;

			.card-body--seemore-text {
				@include arial-reg-12px;
			}

			@include for-sm-down {
				flex-direction: row;
				justify-content: flex-start;
				align-items: center;

				h2 {
					margin-right: .5rem;
				}
			}
		}

		&:hover,
		&:focus {
			border-color: $soft-black;

			.card-footer {
				color: $soft-black;
			}

			h2, .xl-single-character, .card-body--seemore-text {
				color: $soft-black;
			}
		}
	}
}
