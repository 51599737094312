// ----------------------------------------
// Modals
// ----------------------------------------
.modal-backdrop {
  opacity: 0.7 !important;
}

.modal {
  .modal-dialog {
    margin: 200px auto;

    @media only screen and (max-height: 600px) {
      margin: 80px auto;
    }

    @include for-xs-only {
      padding: 0px 20px;
    }

    .modal-content {
      border: 1px solid $dark-gray;
      border-radius: 10px;
      padding: 10px 20px;

      .modal-header {
        border: none;
      }

      .modal-title {
        font-size: 30px;
        text-transform: initial;
        letter-spacing: initial;
      }

      .modal-body {
        font-size: 16px;
        line-height: 23px;
      }

      .modal-footer {
        border: none;
        justify-content: flex-start;

        button {
          min-width: 94px;
        }
      }
    }
  }

  &.custom-link-modal {
    .modal-dialog {
      .modal-content {
        padding: 0px;

        .modal-header {
          padding: 1.25rem;
          border-bottom: 1px solid $pale-gray;

          .modal-title {
            line-height: normal;
          }
        }

        .modal-body,
        .modal-footer {
          padding: 1.25rem;
        }
      }
    }
  }
}
