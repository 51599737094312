// ----------------------------------------
// Social Styles
// ----------------------------------------

.social-links {
    position: relative;

    &:not(.image-cover){
      margin-top: 1rem;
      text-align: right;
    }
}

.share-btn,
.add-btn {
    cursor: pointer;

    svg {
        width: 20px;
        height: 18px;
        fill: $access-blue;
    }

    &:hover svg,
    &:focus svg {
        fill: $moderna-green;
    }

    &[active] svg {
        fill: $moderna-green;
    }

    &[active]:hover svg,
    &[active]:focus svg {
        fill: $access-blue;
    }

    span {
      @extend .ms-2;
    }
}

// Overlay & button styles for Apps page
.social-links--overlay {
    display: flex;
    justify-content: flex-end;
    transition: all 0.5s ease-in-out;

    @include for-md-down {
  		flex-wrap: wrap;
  	}

    .share-btn,
    .add-btn {
        @extend .btn;
        @extend .my-2;
        @extend .mx-3;
        border-radius: 20px;
        --bs-bg-opacity: .5;
        background-color: rgba(255, 255, 255, var(--bs-bg-opacity));
    }
}

.mycorner--open {
  .social-links--overlay {
    flex-wrap: wrap;

    @include for-sm-up {
      padding-right: calc(var(--bs-gutter-x, 0.75rem) + 1rem);
  	}
  }


}
