// ----------------------------------------
// New Hire Global Styles
// ----------------------------------------

.newhire--container-index,
.newhire-index--pagetitle {
	&.everyone {
		.card-badge {
			background-color: $moderna-green;
		}
	}

	&.fulltime {
		.card-badge {
			background-color: $moderna-orange;
		}
	}

	&.manager {
		.card-badge {
			background-color: $moderna-blue;
		}
	}
}

.newhire--container-index,
.newhire--section,
.newhire--index-callout {
	&.everyone {
		border-color: $moderna-green;

		.img-offset,
		.newhire--pagelink {
			border-color: $moderna-green;
		}

		.section-header {
			border-left: 10px solid $moderna-green;
		}

		.newhire--pagelink-title {
			border-color: $moderna-green;
		}
	}

	&.fulltime {
		border-color: $moderna-orange;

		.img-offset,
		.newhire--pagelink {
			border-color: $moderna-orange;
		}

		.section-header {
			border-left: 10px solid $moderna-orange;
		}

		.newhire--pagelink-title {
			border-color: $moderna-orange;
		}
	}

	&.manager {
		border-color: $moderna-blue;

		.img-offset,
		.newhire--pagelink {
			border-color: $moderna-blue;
		}

		.section-header {
			border-left: 10px solid $moderna-blue;
		}

		.newhire--pagelink-title {
			border-color: $moderna-blue;
		}
	}
}
// Global to New Hire area
.newhire--banner,
.newhire--container-image {
	position: relative;
	// background-color: $white;
}

.newhire--banner {
	display: flex;
	flex-direction: column;
	// background-image: inline on HTML
	background-size: cover;
	background-repeat: no-repeat;
	background-position: 100px center;
	min-height: 275px;
}

.newhire--container-image {
	@extend .container-fluid;
	@extend .mt-3;
	padding-bottom: 10rem;

	@include for-md {
		padding-bottom: 5rem;
	}

	@include for-sm-down {
		padding-bottom: 2rem;
	}
}

.page-bottom-image {
	position: absolute;
	z-index: -1;
	right: 0;
	bottom: 0;
	left: 0;
	width: 100%;
	height: auto;
	object-fit: contain;
}
