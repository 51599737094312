// ----------------------------------------
// Spotlight Styles
// ----------------------------------------

.dash-spotlight {
	@extend .mb-5;
	display: block;
	transition: all 0.5s ease-in-out;

	h2 {
		color: $access-blue;
		@extend .mt-3;
		@extend .mb-1;
	}

	.dash-spotlight--card {
		@extend .card;
		@extend .h-100;
		@extend .p-3;
		justify-content: center;
		color: $link-color;
		border-color: $moderna-blue;
		background-color: $white;

		.tile-title {
			margin-bottom: 0px;
		}

		svg {
			width: 10px;
			height: 14px;
			fill: $link-color;
		}

		&:hover,
		&:focus {
			color: $link-hover-color;
			border-color: $link-hover-color;

			svg {
				fill: $link-hover-color;
				transform: scale(1.25);
			}
		}
	}
}
