// ----------------------------------------
// New Hire Dashboard Styles
// ----------------------------------------

.newhire-dashboard {
	.newhire--container-index {
		position: relative;
		display: flex;
		justify-content: space-between;
		align-items: center;
		flex-wrap: nowrap;
		margin: 3rem 75px 3rem 75px;
		padding: 1.5rem;
		border-radius: 6px;
		border-style: solid;
		border-width: 3px;
		background-color: $white;

		&:first-child {
			margin-top: 1rem;
		}

		a {
			svg {
				fill: $link-color;
				width: 10px;
				height: 10px;
			}

			&:hover,
			&:focus {
				svg {
					fill: $link-hover-color;
				}
			}
		}

		.card-badge {
			position: absolute;
			top: -12px;
			left: 6rem;
			ackground-color: $mid-gray;
		}

		.img-offset {
			max-width: 150px;
			max-height: 150px;
			margin-left: calc(-75px - 1.5rem);
			border: 1px solid $mid-gray;
		}

		.newhire--description {
			margin: 0 1rem;
		}

		.newhire--titlelink {
			@extend .h4;

			svg {
				width: 24px;
				height: 14px;
				margin-left: 1.5rem;
			}
		}

		.newhire--pagelink {
			flex: 0 0 auto;
    	width: 33%;
			margin-right: -75px;
			padding: 0px 1rem;
			border-radius: 6px;
			border-style: solid;
			border-width: 1px;
			background-color: $white;

			.newhire--pagelink-link {
				display: flex;
				justify-content: space-between;
				align-items: center;
				margin: .5rem 0;
				padding: .25rem 0;
				border-bottom: 1px solid $mid-gray;

				&:last-child {
					border-bottom: 0px;
				}
			}
		}

		@include for-md-down {
			flex-wrap: wrap;
			margin: 3rem 50px calc(3rem + 75px) 50px;

			.img-offset {
				align-self: flex-start;
				max-width: 100px;
				max-height: 100px;
				margin-left: calc(-50px - 1.5rem);
			}

			.newhire--description {
				width: calc(100% - 65px);
			}

			.newhire--pagelink {
				width: 100%;
				margin-bottom: -75px;
				margin-right: unset;
			}
		}
	}
}
