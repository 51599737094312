// ----------------------------------------
// What's New Styles
// ----------------------------------------

.dash-whatsnew {
	@extend .mb-5;
	@extend .pt-3;
	display: flex;
	flex-direction: column;
	// background-image: url('../img/background-dashboard.jpg');
  background-color: $soft-black;
	background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
	min-height: 300px;
}

.dash-whatsnew--header {
	@extend .container;
	color: $white;
}

.dash-whatsnew--row {
	@extend .row;
	@extend .justify-content-between;
	@extend .align-items-center;
}

// primary card
.dash-whatsnew--primary {
  @extend .col-lg-12;
	margin-bottom: 1.5rem;

	.card-body {
		@extend .d-flex;
		@extend .flex-column;
		@extend .justify-content-center;

		.line-clamped {
			display: -webkit-box;
			-webkit-line-clamp: 3;
			-webkit-box-orient: vertical;
			overflow: hidden;
		}

		h5 {
			@include for-lg-up {
				margin-bottom: 1.5rem;
			}

			@include for-md-down {
				margin-top: 1.5rem;
				margin-bottom: 1.5rem;
			}
		}
	}

	img {
	  @include for-lg-up {
			border-top-right-radius: 3px;
		  border-bottom-right-radius: 3px;
		}

		@include for-md-down {
			border-bottom-right-radius: 3px;
		  border-bottom-left-radius: 3px;
		}
	}
}

// secondary card carousel
.dash-whatsnew--secondary {
	@extend .col-lg-4;
	@extend .mb-4;

	.card-image {
		position: relative;

		img {
			border-top-right-radius: 3px;
			border-top-left-radius: 3px;
			min-height: 155px;
		}

		.card-badge {
	    position: absolute;
	    top: .5rem;
	    left: 1rem;
		}
	}

	.card-body {
		.line-clamped {
			display: -webkit-box;
			-webkit-line-clamp: 5;
			-webkit-box-orient: vertical;
			overflow: hidden;
		}

		h5 {
			@include for-lg-up {
				min-height: 50px;
			}
		}
	}
}

// secondary card NON carousel
.dash-whatsnew--secondary.non-carousel {
	@extend .col-lg-6;

	.card-body {
		.line-clamped {
			-webkit-line-clamp: 3;
		}
	}
}

.dash-whatsnew--primary,
.dash-whatsnew--secondary {
	img {
	  width: 100%;
	  object-fit: cover;
	}

	.card {
	  height: 100%;
	  border: 2px solid $white;
	  border-radius: 6px;

		&:hover,
		&:focus {
			border-color: $link-hover-color;

			.card-badge {
				color: $white;
				background-color: $link-hover-color;
			}
		}

		p {
			margin-bottom: 0px;
		}

		.line-clamped {
			color: $link-hover-color;
		}
	}
}

// BS5 carousel overwrites
.carousel {
	.carousel-inner {
		@include for-md-down {
	    .carousel-item>div {
        display: none
	    }

	    .carousel-item>div:first-child {
        display: block
	    }

			.carousel-item {
				width: 100%;

				.dash-whatsnew--secondary {
					width: 100%;
					height: 325px;

					.line-clamped {
						-webkit-line-clamp: 3;
					}
				}
			}
		}

		@include for-md {
			.carousel-item {
				.dash-whatsnew--secondary {
					height: 385px;
				}
			}
		}

		.carousel-item.active,
		.carousel-item-next,
		.carousel-item-prev {
	    display: flex
		}

		@include for-lg-up {
	    .carousel-item-end.active,
	    .carousel-item-next {
        transform: translateX(33.333%)
	    }

	    .carousel-item-start.active,
	    .carousel-item-prev {
        transform: translateX(-33.333%)
	    }

			.carousel-item {
				.dash-whatsnew--secondary {
					height: 350px;
					padding-right: calc(var(--bs-gutter-x) * .5);
					padding-left: calc(var(--bs-gutter-x) * .5);
				}
			}
		}

		.carousel-item-end,
		.carousel-item-start {
	    transform: translateX(0)
		}
	}

	.carousel-control-prev,
	.carousel-control-next {
		top: calc(50% - 20px);
		z-index: 999;
		width: 40px;
		height: 40px;
		color: $white;
		background: $deep-blue;
		border-radius: 50%;

		&:hover,
		&:focus {
			background: $soft-black;
		}
	}
}
